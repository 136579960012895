// src/App.js
import React, {useEffect, useState} from 'react';
import {useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import Home from './components/Home';
import ItemPage from "./components/ItemPage";
import Schedule from "./components/schedule";
import Review from './components/Review';
import Booking from "./components/Booking";
import NotFound from "./components/404";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/MoveFlairModal.css';
import ReactGA from 'react-ga';

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function AppContent() {
    const location = useLocation();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const isLandingPage = location.pathname === '/landing';


    useEffect(() => {
        const redirectPaths = ['/booking', '/review', '/schedule', '/items', '/home'];

        if (isInitialLoad && redirectPaths.includes(location.pathname)) {
            window.location.href = 'https://www.moveflair.com';
        }

        setIsInitialLoad(false);
    }, [location, isInitialLoad]);

    if (isInitialLoad) {
        return null; // Render nothing while checking for redirect
    }



    return (
        <>
            {!isLandingPage && <Header />}
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/home" element={<Home />} />
                <Route path="/items" element={<ItemPage />} />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/review" element={<Review />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/landing" element={<RedirectToLandingHtml />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {!isLandingPage && <Footer />}
        </>
    );
}
const RedirectToLandingHtml = () => {
    useEffect(() => {
        window.location.href = `${process.env.PUBLIC_URL}/landing.html`;
    }, []);
    return null;
};
export default App;