import React, { useState } from 'react';
import axios from 'axios';
import '../../styles/PhoneVerification.css';

const PhoneVerification = ({ onVerify }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationSid, setVerificationSid] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [error, setError] = useState('');
    const [isServerError, setIsServerError] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleVerify = async () => {
        setLoading(true);
        setError('');
        setIsServerError(false);
        try {
            const response = await axios.post('https://atlas-go.onrender.com/api/v1/verify', {
                phone_number: `+1${phoneNumber}`,
            });
            if (response.status === 200) {
                setVerificationSid(response.data.verification_sid);
                setShowCodeInput(true);
            } else {
                setError(response.data.message || 'Verification failed. Please try again.');
            }
        } catch (error) {
            if (error.response && error.response.status >= 500) {
                setIsServerError(true);
                setError('Server error occurred. Please retype your phone number and try again.');
            } else {
                setError(error.response?.data?.message || 'Error verifying phone number. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCheckCode = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('https://atlas-go.onrender.com/api/v1/check', {
                phone_number: verificationSid,
                code: verificationCode,
            });
            if (response.status === 200 && response.data.valid) {
                setIsVerified(true);
                onVerify(true, `+1${phoneNumber}`, name, email);  // Pass the verified status, phone, name, and email to the parent component
                setError('');
            } else {
                setError('Incorrect verification code. Please try again.');
            }
        } catch (error) {
            if (error.response && error.response.status >= 500) {
                setIsServerError(true);
                setError('Failed to check verification code. Please retype a correct phone number and try again.');
            } else {
                setError(error.response?.data?.message || 'Error checking verification code. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRetype = () => {
        setPhoneNumber('');
        setVerificationCode('');
        setVerificationSid('');
        setShowCodeInput(false);
        setError('');
        setIsServerError(false);
        setIsVerified(false);
    };

    // Update parent component with name and email as they are being typed
    const handleNameChange = (e) => {
        setName(e.target.value);
        if (isVerified) {
            onVerify(true, `+1${phoneNumber}`, e.target.value, email);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        validateEmail(e.target.value);  // Validate email on change
        if (isVerified) {
            onVerify(true, `+1${phoneNumber}`, name, e.target.value);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };

    return (
        <div className="phone-verification">
            {error && <p className="error-message">{error}</p>}
            {!showCodeInput && !isVerified && (
                <>
                    <label>Phone Number required for communication (Canada Only)</label>
                    <div className="phone-input-container">
                        <span className="country-code">+1</span>
                        <input
                            type="number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Enter your phone number"
                            disabled={isVerified || loading}
                        />
                    </div>
                </>
            )}
            {showCodeInput && !isServerError && !isVerified && (
                <div className="code-input-container">
                    <input
                        type="text"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        placeholder="Enter verification code"
                        className="code-input"
                        disabled={isVerified || loading}
                    />
                </div>
            )}
            {isVerified && (
                <div className="success-message">
                    <h3>Your Number is Verified!</h3>
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}  // Real-time name update
                        placeholder="Enter your name"
                        className="name-input"
                        disabled={loading}
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}  // Real-time email update
                        placeholder="Enter your email"
                        className="email-input"
                        disabled={loading}
                    />
                    {emailError && <p className="error-message">{emailError}</p>}  {/* Display email error */}
                </div>
            )}
            {!isVerified && (
                <button
                    className={`verify-button ${loading ? 'loading' : ''}`}
                    onClick={isServerError ? handleRetype : showCodeInput ? handleCheckCode : handleVerify}
                    disabled={loading || isVerified || (!phoneNumber && !verificationCode)}
                >
                    {loading ? (
                        <div className="loader"></div>
                    ) : isServerError ? (
                        'Retype Number'
                    ) : showCodeInput ? (
                        'Submit Code'
                    ) : (
                        'Verify'
                    )}
                </button>
            )}
        </div>
    );
};

export default PhoneVerification;
