// Review.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../components/ProgressSteps/ProgressBar';
import PhoneVerification from "./Authentication/PhoneVerification";
import '../styles/Review.css';
import ReactGA from 'react-ga';
import TimeSlider from '../components/Sliders/TimeSlider';

ReactGA.pageview(window.location.pathname + window.location.search);
const Review = () => {
    const [loading, setLoading] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', phoneNumber: '' });
    const [showModal, setShowModal] = useState(false); // State for showing the modal
    const navigate = useNavigate();
    const { fromAddress, toAddress, distance, serviceDetails, items, schedule,duration, estimatedCost } = useSelector(state => state.move);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave? Your progress will be lost.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    if (!fromAddress || !toAddress) {
        return (
            <div className="p-5">
                <h2 className="text-xl font-bold text-red-500">Address details are missing. Please go back and enter your move details.</h2>
                <button
                    onClick={() => navigate('/')}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                >
                    Go Back
                </button>
            </div>
        );
    }

    if (!serviceDetails) {
        return (
            <div className="p-5">
                <h2 className="text-xl font-bold text-red-500">Service details are missing. Please go back and select a service.</h2>
                <button
                    onClick={() => navigate('/home')}
                    className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-300"
                >
                    Go Back
                </button>
            </div>
        );
    }
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = async () => {
        if (!validateEmail(userDetails.email)) {
            alert('Please enter a valid email address.');
            return;
        }
        setLoading(true);

        const data = {
            "moving_from": fromAddress,
            "moving_to": toAddress,
            "distance": distance,
            "service_type": serviceDetails.serviceType,
            "number_of_movers": parseInt(serviceDetails.numMovers, 10),
            "mover_drives": serviceDetails.isMoveDrives,
            "schedule": schedule.toISOString(),
            "time_hour": parseInt(duration.hours),
            "time_minute": parseInt(duration.minutes),
            "estimated_cost": parseFloat(estimatedCost/2),
            "inventory": items.map(item => ({
                "item": item.furnitureName,
                "description": item.description,
                "quantity": parseInt(item.quantity, 10),
                "images": item.images.length
            })),
            "email": userDetails.email,
            "name": userDetails.name,
            "phone_number": userDetails.phoneNumber
        };

        try {
            const reviewResponse = await axios.post('https://atlas-go.onrender.com/api/v1/move', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const { user_id, booking_id } = reviewResponse.data;

            for (let item of items) {
                const formData = new FormData();

                item.images.forEach((image) => {
                    formData.append('image', image);
                });
                formData.append('user_id', user_id);
                formData.append('booking_id', booking_id);

                await axios.post('https://atlas-go.onrender.com/api/v1/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            navigate('/booking', { state: { booking_id } });
        } catch (error) {
            console.error("Error during submission:", error);
            alert("There was an error submitting your move. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex  = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handlePrevious = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/schedule');
        }, 2000);
    };

    const handleVerification = (verified, phoneNumber, name, email) => {
        setPhoneVerified(verified);
        setUserDetails({ phoneNumber, name, email });
    };

    return (
        <div className="p-5">
            <ProgressBar step="review" />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <PhoneVerification onVerify={handleVerification} />

                <div className="space-y-5 p-4 mb-4 border border-gray-300 rounded-lg shadow-sm">
                    <div className="flex justify-center">
                        <h2 className="text-xl font-bold text-center">Move Summary</h2>
                    </div>                    <h3 className="text-xl font-bold mt-4">Estimated Cost:</h3>
                    <p><strong>Price Estimate:</strong> <s>${estimatedCost.toFixed(2)}</s> &nbsp;<span className="text-green-600">${(estimatedCost/2).toFixed(2)}</span></p>
                   <p>Estimate may vary based on actual time. Final cost will be adjusted after the move.</p>
                    <div className="price-estimation">
                        <button
                            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full w-full transition duration-300 ease-in-out transform hover:scale-105"
                            onClick={handleShowModal}
                        >
                            View Full Price Estimate
                        </button>
                    </div>
                    <hr/>
                    <p><strong>Moving From:</strong> {fromAddress}</p>
                    <p><strong>Moving To:</strong> {toAddress}</p>
                    <p><strong>Distance:</strong> {distance.toFixed(2)} km</p>
                    <p><strong>Service Type:</strong> {serviceDetails.serviceType === "moverDrives" ? "Driver & Mover Service" : serviceDetails.serviceType === "movers" ? "Movers Only Service" : serviceDetails.serviceType}</p>
                    <p><strong>Number of Movers:</strong> {serviceDetails.numMovers}</p>
                    <p><strong>Schedule:</strong> {new Date(schedule).toLocaleString()}</p>
                    <p><strong>Estimated Duration:</strong> {duration.hours} hour(s) and {duration.minutes} minute(s)</p>
                    <hr />

                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <div key={index}>
                                <h3 className="text-xl font-bold">Furniture Inventory</h3>
                                <p><strong>Furniture Name:</strong> {item.furnitureName}</p>
                                <p><strong>Description:</strong> {item.description}</p>
                                <p><strong>Quantity:</strong> {item.quantity}</p>
                                <p><strong>Images Uploaded:</strong> {item.images.length}</p>
                            </div>
                        ))
                    ) : (
                        <p>No items have been added yet.</p>
                    )}
                </div>
            </div>
            {/* Modal for TimeSlider */}
            {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-5 rounded-lg max-w-lg w-full">
                        <TimeSlider
                            serviceType={serviceDetails.serviceType}
                            numMovers={serviceDetails.numMovers}
                            closeModal={handleCloseModal}
                        />
                    </div>
                </div>
            )}
            <div className="fixed bottom-0 left-0 right-0 flex justify-center space-x-1 p-3 bg-white shadow-lg">
                <button
                    onClick={handlePrevious}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
                >
                    Previous
                </button>
                <button
                    onClick={handleSubmit}
                    className={`py-2 px-4 rounded transition-colors duration-300 ${
                        phoneVerified && userDetails.name && userDetails.email
                            ? 'bg-orange-500 hover:bg-orange-700 text-white'
                            : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                    }`}
                    disabled={!phoneVerified || !userDetails.name || !userDetails.email || loading}
                >
                    Submit
                </button>
            </div>

            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
                    <p className="mt-4 text-xl font-bold text-blue-500">Loading...</p>
                </div>
            )}
        </div>
    );
};

export default Review;
